import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../Store/auth-context";

const PCFavorite = ({ productid }) => {
  const authObject = useContext(AuthContext);
  const [markFav, setMarkFav] = useState();
  const [user, setUser] = useState(authObject.user);

  useEffect(() => {
    if (authObject.user && authObject.favorites) {
      setMarkFav(authObject.favorites.includes(productid) ? true : false);
    }
  }, [productid]);

  const updateUserDetails = async (user, favorites) => {
    user.webshop = authObject.webshoptoken;
    user.favorites = favorites;
    var url = `${process.env.REACT_APP_API_URL}api/userdetails/update/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("access"),
      },
      body: JSON.stringify(user),
    })
      .then((resp) => {
        if (resp.ok) {
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function changeFavorite() {
    setMarkFav(!markFav);
    let array = authObject.favorites;
    if (array.includes(productid)) {
      let index = array.indexOf(productid);
      array.splice(index, 1);
    } else {
      array.push(productid);
    }
    updateUserDetails(user, array);
  }

  return (
    <>
      {authObject.user ? (
        <>
          <button
            className="border-1 cardfavorite fs-3 bg-grey"
            onClick={() => {
              changeFavorite();
            }}
          >
            {markFav ? (
              <i className="bi bi-heart-fill"></i>
            ) : (
              <i className="bi bi-heart"></i>
            )}
          </button>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PCFavorite;
